import {Provider} from "react-redux"
import * as React from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {PersistGate} from 'redux-persist/lib/integration/react';
import createStore from "./src/stores/store";
import SimpleReactLightbox from 'simple-react-lightbox'
import CookieGtag from "./src/components/Cookies/CookieGtag";
import {useEffect} from "react";
import {Usersnap} from "./src/components/Usersnap/Usersnap";

let {store, persistor} = createStore();
// eslint-disable-next-line react/display-name,react/prop-types
export default ({element}) => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts

    return (
        <>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <CookieGtag/>
                    <SimpleReactLightbox>
                        {element}
                    </SimpleReactLightbox>
                </PersistGate>
            </Provider>
            <ToastContainer/>
        </>
    )


}